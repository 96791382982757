<template>
  <b-list-group-item
    class="entity"
    button
    @click.stop="openRequirement()"
  >
    <div class="d-flex flex-column">
      <div class="d-inline-flex w-100 justify-content-between">
        <div class="mb-0">
          <!-- Forward Trace (Coverage) -->
          <abbr
            v-if="isForwardTrace"
            v-b-popover.leftbottom.hover.html="`${item.from.object_text}`"
            :title="item.from.display_id"
            class="mr-1 text-nowrap font-weight-bolder text-primary"
          >
            {{ item.from.display_id }}
          </abbr>

          <!-- Backward Trace (Trace) -->
          <abbr
            v-else
            v-b-popover.leftbottom.hover.html="`${item.to.object_text}`"
            :title="item.to.display_id"
            class="mr-1 text-nowrap font-weight-bolder text-primary"
          >
            {{ item.to.display_id }}
          </abbr>
        </div>

        <b-badge
          :variant="item.type === 'blocks' ? 'danger' : 'info'"
          class="text-uppercase"
        >
          {{ item.type }}
        </b-badge>
      </div>

      <div>
        <p class="my-50 font-small-4">
          {{ item.justification ? item.justification : 'No justification' }}
        </p>

        <p class="mb-0 font-small-3 text-muted text-nowrap">
          Created by {{ getUserUsername(item.created_by) }}
        </p>

        <p class="mb-0 font-small-3 text-muted">
          Created {{ item.created | informalDate }}
        </p>
      </div>
    </div>
  </b-list-group-item>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'ListGroupItemRequirementTrace',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isForwardTrace: {
      type: Boolean,
      required: false,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openRequirement() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you wish to leave this page? Any unsaved changes will be lost.', {
          title: 'Open Requirement',
          size: 'sm',
          okVariant: 'success',
          okTitle: 'Open Requirement',
          cancelTitle: 'Dismiss',
          cancelVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(confirmed => {
          if (confirmed) {
            this.$router.push({
              name: 'legacy_requirements_table_focus_on_id',
              params: { specId: this.item.spec_id },
              query: { focus: this.item.id },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.entity {
  background-color: rgba(22, 29, 49, 0.02) !important;
  border: 1px dashed;
  border-left: 0;
  border-right: 0;
}

.entity:hover {
  cursor: pointer;
  background-color: rgba(22, 29, 49, 0.05) !important;
}

.dark-layout {
  .entity {
    background-color: rgba(22, 29, 49, 0.3) !important;
  }

  .entity:hover {
    background-color: rgba(22, 29, 49, 1) !important;
  }
}
</style>
